import React from "react";
import { ShadowHeaderParagraphContainer, ShadowHeaderText } from "./styles";

type PpShadowHeaderParagraphBlock = {
  paragraph: string;
  shadowHeader: string;
};

export function ShadowHeaderParagraphBlock({
  paragraph,
  shadowHeader,
}: PpShadowHeaderParagraphBlock): React.ReactElement {
  return (
    <ShadowHeaderParagraphContainer>
      <ShadowHeaderText
        gutterBottom
        align="center"
        color="primary.light"
        component="p"
        variant="section--name_intro"
      >
        {shadowHeader}
      </ShadowHeaderText>
      <ShadowHeaderText
        align="center"
        color="primary"
        component="p"
        variant="intro"
      >
        {paragraph}
      </ShadowHeaderText>
    </ShadowHeaderParagraphContainer>
  );
}
